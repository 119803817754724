import React, { useState, useRef } from 'react';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import './InputBox.css';

const InputBox = ({ onSend, onFileUpload, disabled, uploadedFiles, setUploadedFiles }) => {
  const [message, setMessage] = useState('');
  const [isDragging, setIsDragging] = useState(false); // 用于标识是否正在拖拽文件
  const fileInputRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleSend = () => {
    if (message.trim() === '' && uploadedFiles.length === 0) return;
    onSend(message);
    setMessage('');
    setUploadedFiles([]);
    fileInputRef.current.value = '';
  };

  const handleFileUpload = (files) => {
    Array.from(files).forEach((file) => {
      onFileUpload(file);
    });
  };

  const handleInputChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      handleFileUpload(files);
    }
  };

  const triggerFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleRemoveFile = (fileName) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      handleFileUpload(files);
    }
  };

  const handlePaste = (e) => {
    const items = e.clipboardData.items;
    Array.from(items).forEach((item) => {
      if (item.kind === 'file') {
        const file = item.getAsFile();
        if (file) {
          handleFileUpload([file]);
        }
      }
    });
  };

  return (
    <div
      className={`input-box-container ${isDragging ? 'dragging' : ''}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onPaste={handlePaste} // 监听粘贴事件
    >
      {uploadedFiles && uploadedFiles.length > 0 && (
        <div className="uploaded-files-list">
          {uploadedFiles.map((file, index) => (
            <div key={index} className="uploaded-file-item">
              <a href={URL.createObjectURL(file)} download={file.name} className="uploaded-file-name">
                {file.name}
              </a>
              <CloseIcon
                className="remove-file-icon"
                onClick={() => handleRemoveFile(file.name)}
              />
            </div>
          ))}
        </div>
      )}
      <div className="input-box">
        <textarea
          className="input-textarea"
          placeholder="Type a message or drag/paste files here..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
        <button className="upload-button" onClick={triggerFileUpload} disabled={disabled}>
          <AttachFileIcon />
        </button>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleInputChange}
          disabled={disabled}
          multiple
        />
        <button className="send-button" onClick={handleSend} disabled={disabled}>
          <SendIcon />
        </button>
      </div>
    </div>
  );
};

export default InputBox;
