// Subscription.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getApiBaseUrl } from './api';
import { Button, CircularProgress, Alert } from '@mui/material';
import './Subscription.css';

const Subscription = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiUrl = getApiBaseUrl();

  const handleUpgradeToPro = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const response = await axios.post(
        `${apiUrl}/create-checkout-session`,
        {},
        {
          headers: {
            'X-User-Id': user.id,
          },
        }
      );

      const { url } = response.data;

      window.location.href = url;
    } catch (error) {
      console.error('Failed to initiate upgrade:', error);
      setError('Failed to initiate upgrade. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      setIsLoading(true);
      setError(null);

      await axios.post(
        `${apiUrl}/cancel-subscription`,
        {},
        {
          headers: {
            'X-User-Id': user.id,
          },
        }
      );

      alert('Your subscription has been canceled.');
    } catch (error) {
      console.error('Failed to cancel subscription:', error);
      setError('Failed to cancel subscription. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="subscription-container">
      <h2>Manage Your Subscription</h2>

      {error && (
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className="subscription-actions">
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpgradeToPro}
            style={{ marginRight: '20px' }}
          >
            Upgrade to Pro
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleCancelSubscription}>
            Cancel Subscription
          </Button>
        </div>
      )}
    </div>
  );
};

export default Subscription;