import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; // 支持 GitHub 风格的 Markdown
import remarkMath from 'remark-math'; // 支持数学公式
import rehypeKatex from 'rehype-katex'; // 渲染数学公式
import rehypeHighlight from 'rehype-highlight'; // 支持代码高亮

import 'katex/dist/katex.min.css'; // 引入 KaTeX 样式
import 'highlight.js/styles/github.css'; // 引入代码高亮样式

const MessageContent = ({ content }) => {
  // 处理内容为字符串的情况
  if (typeof content === 'string') {
    return (
      <ReactMarkdown
        children={content}
        remarkPlugins={[remarkGfm, remarkMath]} // 支持表格、任务列表、数学公式等
        rehypePlugins={[rehypeKatex, rehypeHighlight]} // 渲染公式和代码高亮
      />
    );
  }

  // 处理内容为数组的情况
  if (Array.isArray(content)) {
    // 扁平化数组，防止嵌套数组的情况
    const flatContent = content.flat(Infinity);

    return (
      <div>
        {flatContent.map((item, index) => {
          if (typeof item === 'string') {
            // 渲染文本
            return (
              <ReactMarkdown
                key={index}
                children={item}
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeKatex, rehypeHighlight]}
              />
            );
          } else if (item.type === 'image') {
            // 渲染图片
            return (
              <img
                key={index}
                src={item.src}
                alt={item.alt}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  borderRadius: '8px',
                  marginTop: '8px',
                }}
              />
            );
          } else if (item.type === 'attachment') {
            // 渲染附件
            return (
              <div key={index} style={{ marginTop: '8px' }}>
                <p style={{ marginBottom: '4px', color: '#ffffff' }}>
                  📎 Attachment: {item.name}
                </p>
                <a
                  href={item.href}
                  download={item.name}
                  style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    display: 'inline-block',
                    fontWeight: 'bold',
                  }}
                >
                  Click to Download
                </a>
              </div>
            );
          } else {
            // 不支持的内容类型
            console.warn('Unsupported content type:', item);
            return null;
          }
        })}
      </div>
    );
  }

  // 不支持的内容格式
  console.warn('Unsupported content format:', content);
  return <div style={{ color: 'red' }}>Unsupported content format</div>;
};

export default MessageContent;