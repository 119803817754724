// Chat.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import InputBox from './components/InputBox';
import MessageContent from './components/MessageContent';
import ChatSidebar from './components/ChatSidebar';
import './Chat.css';
import { Avatar, Menu, MenuItem, CircularProgress, Alert, IconButton } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { getApiBaseUrl } from './api';

const Chat = ({ user, onLogout }) => {
  const [conversations, setConversations] = useState([]);
  const [messages, setMessages] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const messagesEndRef = useRef(null);

  const { conversationId } = useParams();
  const navigate = useNavigate();
  const currentConversationId = conversationId || '';

  useEffect(() => {
    fetchConversations();
  }, [user.id]);

  useEffect(() => {
    if (currentConversationId) {
      fetchMessages();
    }
  }, [currentConversationId, user.id]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const apiUrl = getApiBaseUrl();

  const fetchConversations = async () => {
    try {
      setError(null);
      const response = await axios.get(`${apiUrl}/conversations`, {
        headers: {
          'X-User-Id': user.id,
        },
      });
      setConversations(response.data);
      if (response.data.length > 0) {
        if (!conversationId) {
          navigate(`/conversation/${response.data[0].id}`);
        }
      } else {
        createNewConversation('New Chat');
      }
    } catch (error) {
      console.error('Failed to load conversations:', error);
      setError('Failed to load conversations. Please try again.');
    }
  };

  const fetchMessages = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const response = await axios.get(
        `${apiUrl}/conversations/${currentConversationId}/messages`,
        {
          headers: {
            'X-User-Id': user.id,
          },
        }
      );

      // 处理消息内容
      const adjustedMessages = response.data.map((msg) => {
        let contentArray = Array.isArray(msg.content) ? msg.content : [msg.content];

        contentArray = contentArray.map((contentItem) => {
          if (typeof contentItem === 'string') {
            return contentItem;
          } else if (contentItem.type === 'image') {
            return contentItem;
          } else if (contentItem.type === 'attachment') {
            return {
              type: 'attachment',
              name: contentItem.name,
              href: contentItem.href,
            };
          } else {
            return contentItem;
          }
        });

        return {
          ...msg,
          content: contentArray,
        };
      });

      setMessages(adjustedMessages);
    } catch (error) {
      console.error('Failed to load messages:', error);
      setError('Failed to load messages. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const updateMessages = (newMessage) => {
    setMessages((prev) => [...prev, newMessage]);
  };

  const handleRenameConversation = async (id) => {
    const newTitle = prompt('Enter new conversation title:');
    if (newTitle) {
      try {
        setError(null);
        await axios.put(
          `${apiUrl}/conversations/${id}`,
          { title: newTitle },
          {
            headers: {
              'X-User-Id': user.id,
            },
          }
        );
        setConversations((prev) =>
          prev.map((conv) => (conv.id === id ? { ...conv, title: newTitle } : conv))
        );
      } catch (error) {
        console.error('Failed to rename conversation:', error);
        setError('Failed to rename conversation. Please try again.');
      }
    }
  };

  const handleDeleteConversation = async (id) => {
    if (window.confirm('Are you sure you want to delete this conversation?')) {
      try {
        setError(null);
        await axios.delete(`${apiUrl}/conversations/${id}`, {
          headers: {
            'X-User-Id': user.id,
          },
        });
        setConversations((prev) => prev.filter((conv) => conv.id !== id));
        if (id === currentConversationId.toString()) {
          navigate('/');
        }
      } catch (error) {
        console.error('Failed to delete conversation:', error);
        setError('Failed to delete conversation. Please try again.');
      }
    }
  };

  const createNewConversation = async (title) => {
    try {
      setError(null);
      const response = await axios.post(
        `${apiUrl}/conversations`,
        { title },
        {
          headers: {
            'X-User-Id': user.id,
          },
        }
      );
      setConversations((prev) => [...prev, response.data]);
      navigate(`/conversation/${response.data.id}`);
      setMessages([]);
    } catch (error) {
      console.error('Failed to create conversation:', error);
      setError('Failed to create conversation. Please try again.');
    }
  };

  const handleNewConversation = () => {
    const title = prompt('Enter conversation title:');
    if (title) {
      createNewConversation(title);
    }
  };

  const handleSelectConversation = (id) => {
    navigate(`/conversation/${id}`);
  };

  const onFileUpload = (file) => {
    setUploadedFiles((prevFiles) => [...prevFiles, file]);
  };

  const sendMessage = async (message) => {
    if (message.trim() === '' && uploadedFiles.length === 0) return;

    if (!currentConversationId) {
      console.error('No conversation ID available.');
      setError('Please select or create a conversation first.');
      return;
    }

    setInputDisabled(true);
    setIsLoading(true);
    setError(null);

    // 构建用户消息内容
    const userMessageContent = [];
    if (message.trim() !== '') {
      userMessageContent.push(message);
    }
    uploadedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Content = e.target.result;
        userMessageContent.push({
          type: 'attachment',
          name: file.name,
          href: base64Content,
        });

        // 立即更新消息
        const userMessage = { content: userMessageContent, is_user: true };
        updateMessages(userMessage);
      };
      reader.readAsDataURL(file);
    });

    // 如果没有文件，立即更新消息
    if (uploadedFiles.length === 0) {
      const userMessage = { content: userMessageContent, is_user: true };
      updateMessages(userMessage);
    }

    try {
      const formData = new FormData();
      formData.append('conversation_id', currentConversationId);
      formData.append('content', message);
      uploadedFiles.forEach((file) => {
        formData.append('uploaded_files', file);
      });

      const response = await fetch(`${apiUrl}/messages`, {
        method: 'POST',
        headers: {
          'X-User-Id': user.id,
        },
        body: formData,
      });

      // 发送后重置上传的文件
      setUploadedFiles([]);

      const reader = response.body.getReader();
      let buffer = '';

      // 立即处理数据
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          // 处理可能剩余的 buffer
          if (buffer.length > 0) {
            processBuffer();
          }
          break;
        }

        const chunk = new TextDecoder().decode(value);
        buffer += chunk;

        processBuffer(); // 立即处理接收到的数据
      }

      function processBuffer() {
        // 按双换行符分割缓冲区
        const lines = buffer.split('\n\n');
        // 移除并存储末尾可能不完整的行
        if (!buffer.endsWith('\n\n')) {
          buffer = lines.pop();
        } else {
          buffer = '';
        }

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const data = JSON.parse(line.slice(6));
              handleData(data);
            } catch (error) {
              console.error('Failed to parse data:', error);
            }
          }
        }
      }

      function handleData(data) {
        console.log('handleData called with data:', data);

        if (data.type === 'message') {
          if (data.start) {
            setMessages((prev) => [...prev, { content: [], is_user: false }]);
          } else if (data.end) {
            // 什么都不做
          } else if (data.content) {
            setMessages((prev) => {
              const newMessages = [...prev];
              const lastMessageIndex = newMessages.length - 1;
              const lastMessage = newMessages[lastMessageIndex];

              // 创建 lastMessage 的副本
              const updatedLastMessage = { ...lastMessage };

              // 创建 content 的副本
              let newContent;
              if (!Array.isArray(updatedLastMessage.content)) {
                newContent = [updatedLastMessage.content];
              } else {
                newContent = [...updatedLastMessage.content];
              }

              // 更新文本内容
              const textIndex = newContent.findIndex((item) => typeof item === 'string');
              if (textIndex !== -1) {
                newContent[textIndex] += data.content;
              } else {
                newContent.push(data.content);
              }

              // 更新 lastMessage 的内容
              updatedLastMessage.content = newContent;

              // 更新 messages 数组
              newMessages[lastMessageIndex] = updatedLastMessage;

              return newMessages;
            });
          }
        } else if (data.type === 'image') {
          console.log('Processing image data:', data.content);

          data.content.forEach((image) => {
            const mimeType = image.name.endsWith('.png') ? 'image/png' : 'image/jpeg';
            const imageContent = {
              type: 'image',
              src: `data:${mimeType};base64,${image.image}`,
              alt: image.name,
            };

            setMessages((prev) => {
              const newMessages = [...prev];
              const lastMessageIndex = newMessages.length - 1;
              const lastMessage = newMessages[lastMessageIndex];

              // 创建 lastMessage 的副本
              const updatedLastMessage = { ...lastMessage };

              // 创建 content 的副本
              let newContent;
              if (!Array.isArray(updatedLastMessage.content)) {
                newContent = [updatedLastMessage.content];
              } else {
                newContent = [...updatedLastMessage.content];
              }

              // 检查是否已有相同的图片
              const imageExists = newContent.some(
                (item) => item.type === 'image' && item.src === imageContent.src
              );
              if (!imageExists) {
                newContent.push(imageContent);
              } else {
                console.warn('Duplicate image detected:', imageContent);
              }

              // 更新 lastMessage 的内容
              updatedLastMessage.content = newContent;

              // 更新 messages 数组
              newMessages[lastMessageIndex] = updatedLastMessage;

              return newMessages;
            });
          });
        } else if (data.type === 'attachment') {
          console.log('Processing attachment data:', data.content);

          data.content.forEach((attachment) => {
            // 确保 href 和文件名正确存在
            const attachmentContent = {
              type: 'attachment',
              name: attachment.name,
              href: attachment.href, // 直接使用后端提供的 base64 数据
            };

            setMessages((prev) => {
              const newMessages = [...prev];
              const lastMessageIndex = newMessages.length - 1;
              const lastMessage = newMessages[lastMessageIndex];

              // 创建 lastMessage 的副本
              const updatedLastMessage = { ...lastMessage };

              // 创建 content 的副本
              let newContent;
              if (!Array.isArray(updatedLastMessage.content)) {
                newContent = [updatedLastMessage.content];
              } else {
                newContent = [...updatedLastMessage.content];
              }

              // 检查是否已有相同的附件
              const attachmentExists = newContent.some(
                (item) => item.type === 'attachment' && item.href === attachmentContent.href
              );
              if (!attachmentExists) {
                newContent.push(attachmentContent);
              } else {
                console.warn('Duplicate attachment detected:', attachmentContent);
              }

              // 更新 lastMessage 的内容
              updatedLastMessage.content = newContent;

              // 更新 messages 数组
              newMessages[lastMessageIndex] = updatedLastMessage;

              return newMessages;
            });
          });
        }
      }
    } catch (error) {
      console.error('Failed to send message:', error);
    } finally {
      setIsLoading(false);
      setInputDisabled(false);
    }
  };

  // 菜单状态和处理函数
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="chat-container">
      <Helmet>
        {/* 标题 */}
        <title> Siege Investment - Financial Research AI </title>

        {/* Meta 描述 */}
        <meta
          name="description"
          content={
            currentConversationId
              ? `Join Conversation ${currentConversationId} on Siege Investment to analyze financial data with advanced AI tools. Upload your data and get insights instantly.`
              : `Discover Siege Investment, your trusted platform for real-time financial data analysis and insights. Upload your data and start analyzing now.`
          }
        />

        {/* Open Graph */}
        <meta property="og:title" content="Financial Research AI - Siege Investment" />
        <meta
          property="og:description"
          content="Visualize real-time financial data and analyze uploaded data with advanced AI tools on Siege Investment."
        />
        <meta property="og:image" content="https://siegeinvestment.com/og-image.png" />
        <meta property="og:url" content="https://siegeinvestment.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Siege Investment - Financial Research AI" />
        <meta
          name="twitter:description"
          content="Visualize real-time financial data and analyze uploaded data with advanced AI tools on Siege Investment."
        />
        <meta name="twitter:image" content="https://siegeinvestment.com/twitter-image.png" />
      </Helmet>
      <ChatSidebar
        conversations={conversations}
        onSelectConversation={handleSelectConversation}
        onNewConversation={handleNewConversation}
        onRenameConversation={handleRenameConversation}
        onDeleteConversation={handleDeleteConversation}
        currentConversationId={currentConversationId.toString()}
      />
      <div className="chat-main">
        <div className="chat-header">
          <div></div>
          <IconButton onClick={handleAvatarClick} size="small" sx={{ ml: 2 }}>
            <Avatar src={user.avatar} alt={user.name} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            container={document.body}
          >
            <MenuItem
              onClick={() => {
                handleMenuClose();
                navigate('/subscription');
              }}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              My Subscription
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClose();
                onLogout();
              }}
            >
              Log Out
            </MenuItem>
          </Menu>
        </div>

        {error && (
          <Alert severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        )}

        <div className="chat-messages">
          {messages.map((msg, index) => (
            <div
              key={msg.id || index}
              className={`chat-message ${msg.is_user ? 'user' : 'bot'}`}
            >
              <MessageContent content={msg.content} />
            </div>
          ))}
          {isLoading && (
            <div className="loading-indicator">
              <CircularProgress size={20} />
              <span style={{ marginLeft: '10px' }}>Assistant is typing...</span>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>

        <InputBox
          onSend={sendMessage}
          onFileUpload={onFileUpload}
          disabled={inputDisabled || isLoading}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
        />
      </div>
    </div>
  );
};

export default Chat;