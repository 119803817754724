// src/api.js
export const getApiBaseUrl = () => {
  const hostname = window.location.hostname;
  if (hostname === 'localhost') {
    return 'http://localhost:8080/api/api';
  } else if (hostname === 'uat-siegeinvestment.com' || hostname === 'www.uat-siegeinvestment.com') {
    return 'https://uat-siegeinvestment.com/api/api/api';
  } else {
    return 'https://siegeinvestment.com/api/api/api';
  }
};
