// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import Chat from './Chat';
import './App.css';
import Subscription from './Subscription';

function App() {
  const [user, setUser] = useState(null);

  // 在应用启动时，检查 localStorage 是否有用户信息
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const handleLoginSuccess = userInfo => {
    setUser(userInfo);
    // 将用户信息存储到 localStorage
    localStorage.setItem('user', JSON.stringify(userInfo));
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  return (
    <Router>
      {!user ? (
        <Routes>
          <Route
            path="/*"
            element={<LoginPage onLoginSuccess={handleLoginSuccess} />}
          />
        </Routes>
      ) : (
        <Routes>
          <Route path="/conversation/:conversationId" element={<Chat user={user} onLogout={handleLogout} />} />
          <Route path="/subscription" element={<Subscription user={user}/>} />
          <Route path="/*" element={<Chat user={user} onLogout={handleLogout} />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;
