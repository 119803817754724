// src/components/ChatSidebar.js

import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import './ChatSidebar.css';

const ChatSidebar = ({ conversations, onSelectConversation, onNewConversation, onRenameConversation, onDeleteConversation, currentConversationId }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`chat-sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="sidebar-header">
        <button className="menu-button" onClick={toggleSidebar}>
          <MenuIcon />
        </button>
        {!isCollapsed && (
          <button className="new-conversation-button" onClick={onNewConversation}>
            <AddIcon fontSize="small" />
            <span>New Chat</span>
          </button>
        )}
      </div>
      {!isCollapsed && (
        <div className="sidebar-content">
          {conversations.map(conv => (
            <div
              key={conv.id}
              className={`conversation-item ${conv.id === currentConversationId ? 'active' : ''}`}
            >
              <div className="conversation-title" onClick={() => onSelectConversation(conv.id)}>
                {conv.title}
              </div>
              <div className="conversation-actions">
                <button className="action-button" onClick={() => onRenameConversation(conv.id)}>
                  <EditIcon fontSize="small" />
                </button>
                <button className="action-button" onClick={() => onDeleteConversation(conv.id)}>
                  <DeleteIcon fontSize="small" />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChatSidebar;