import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import './LoginPage.css';
import { getApiBaseUrl } from '../api';
import { Helmet } from "react-helmet";

const LoginPage = ({ onLoginSuccess }) => {
  const apiUrl = getApiBaseUrl();
  const [currentExample, setCurrentExample] = useState(0);

  // SEO Meta Data
  const seoData = {
    title: 'Siege Investment | AI-Powered Financial Research Platform',
    description: 'Transform your investment research with AI-powered analytics, real-time financial data, and advanced visualization tools. Get started with Siege Investment today.',
    keywords: 'finance chatbot, financial market gpt, financial research, AI investing, trading strategies, market analysis, siege investment',
  };

  const scrollToLogin = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const examples = [
    'Analyze real-time financial data',
    'Visualize trading strategies',
    'AI-powered market analysis',
    'Seamless workflow integration'
  ];

  const features = [
    {
      icon: '📊',
      title: 'Real-Time Financial Data & News',
      description: 'Connected to proprietary financial database for instant market insights'
    },
    {
      icon: '📈',
      title: 'Advanced Data Visualization',
      description: 'Powerful coding capability to visualize any trading strategy with data'
    },
    {
      icon: '🔄',
      title: 'Bring Your Knowledge',
      description: 'Upload any data locally and let AI help you with analysis and visualization'
    },
    {
      icon: '⚡',
      title: 'Seamless Integration',
      description: 'Download files and images instantly to integrate into your workflow'
    }
  ];

  const pricingPlans = [
    {
      name: 'Free',
      price: '0',
      period: 'forever',
      features: [
        'Access to top AI models and real-time financial market data',
        'Upload files for visualization and analysis',
        'Download generated images and files',
        'Daily usage up to 20 times'
      ]
    },
    {
      name: 'Pro',
      price: '9.99',
      period: 'per month',
      features: [
        'Unlimited usage of all free features',
        'Access to the latest AI models',
        'Full access to all real-time data (Fundamental, Price Target, ESG, etc.)',
        'Dedicated customer service'
      ],
      highlighted: true
    }
  ];

  const handleSuccess = async credentialResponse => {
    try {
      const res = await axios.post(`${apiUrl}/login`, {
        token: credentialResponse.credential,
      });
      onLoginSuccess(res.data.user);
    } catch (error) {
      console.error('Login failed:', error.response?.data || error.message);
    }
  };

  const handleError = () => {
    console.error('Login failed');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentExample(prev => (prev + 1) % examples.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [examples.length]);

  return (
    <div className="login-page">
      <Helmet>
        <title>{seoData.title}</title>
        <meta name="description" content={seoData.description} />
        <meta name="keywords" content={seoData.keywords} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:description" content={seoData.description} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seoData.title} />
        <meta name="twitter:description" content={seoData.description} />
        <link rel="canonical" href="https://www.siegeinvestment.com" />
      </Helmet>
      <nav className="navbar glass-effect">
        <div className="nav-logo">
          <span className="logo-text">Siege Investment</span>
        </div>
        <ul className="nav-links">
          <li><a href="#features">Features</a></li>
          <li><a href="#pricing">Pricing</a></li>
          <li><a href="#faq">FAQ</a></li>
          <li>
            {/* 修改导航栏的 Get Started 按钮，点击后滚动到登录位置 */}
            <a href="#" className="nav-cta" onClick={(e) => {
              e.preventDefault();
              scrollToLogin();
            }}>Get Started For Free</a>
          </li>
        </ul>
      </nav>

      <div className="hero-section">
        <div className="login-container">
          <div className="left-section">
            <div className="hero-content">
              <Typography variant="h1" className="hero-title animate-text">
                AI-powered <span className="gradient-text">Financial Research</span> Platform
              </Typography>
              <br /><br />
              {/* 使用自定义样式的 GoogleLogin 组件 */}
              <div className="custom-google-login">
                <GoogleLogin
                  onSuccess={handleSuccess}
                  onError={handleError}
                  useOneTap={false}
                  render={renderProps => (
                    <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="custom-login-button">
                      Get Started For Free
                    </button>
                  )}
                />
              </div>
            </div>
          </div>

          <div className="right-section">
            <div className="example-showcase glass-effect">
              <div className="example-header">
                <h3>Transform Your Investment Research</h3>
              </div>
              <div className="example-carousel">
                {examples.map((example, index) => (
                  <div
                    key={index}
                    className={`example-item ${currentExample === index ? 'active' : ''}`}
                  >
                    <div className="example-content">
                      <div className="example-image">
                        {/* Image placeholder - replace with your actual images */}
                      </div>
                      <Typography variant="h5">{example}</Typography>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="features" className="section-features">
        <div className="section-header">
          <h2>Core Features</h2>
          <p>Empower your investment decisions with our advanced tools</p>
        </div>
        <div className="features-grid">
          {features.map((feature, index) => (
            <div key={index} className="feature-card glass-effect">
              <div className="feature-icon">{feature.icon}</div>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section id="pricing" className="section-pricing">
        <div className="section-header">
          <h2>Simple, Transparent Pricing</h2>
          <p>Choose the plan that's right for you</p>
        </div>
        <div className="pricing-grid">
          {pricingPlans.map((plan, index) => (
            <div
              key={index}
              className={`pricing-card glass-effect ${plan.highlighted ? 'highlighted' : ''}`}
            >
              <div className="pricing-header">
                <h3>{plan.name}</h3>
                <div className="pricing-amount">
                  <span className="currency">$</span>
                  <span className="amount">{plan.price}</span>
                  <span className="period">/{plan.period}</span>
                </div>
              </div>
              <ul className="pricing-features">
                {plan.features.map((feature, featureIndex) => (
                  <li key={featureIndex}>✓ {feature}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>

      <section id="faq" className="section-faq">
        <div className="section-header">
          <h2>Frequently Asked Questions</h2>
          <p>Got questions? We've got answers.</p>
        </div>
        <div className="faq-grid">
          <div className="faq-item glass-effect">
            <h4>What is Siege Investment?</h4>
            <p>Siege Investment is an AI-powered financial research platform that provides real-time analytics, market insights, and advanced visualization tools.</p>
          </div>
          <div className="faq-item glass-effect">
            <h4>How can I use the platform?</h4>
            <p>You can analyze real-time financial data, visualize trading strategies, and integrate the results seamlessly into your workflow.</p>
          </div>
          <div className="faq-item glass-effect">
            <h4>What data can I analyze?</h4>
            <p>You can analyze both our proprietary financial database and your own uploaded data using our advanced AI tools.</p>
          </div>
          <div className="faq-item glass-effect">
            <h4>How do I get started?</h4>
            <p>Simply sign up with your Google account to begin exploring our platform's capabilities.</p>
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <h4>Quick Links</h4>
            <ul>
              <li><a href="#features">Features</a></li>
              <li><a href="#pricing">Pricing</a></li>
              <li><a href="#faq">FAQ</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Legal</h4>
            <ul>
              <li><a href="#terms-of-service">Terms of Service</a></li>
              <li><a href="#privacy-policy">Privacy Policy</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Contact</h4>
            <p><a href="mailto:george.y.zhi@siegeinvestment.com">george.y.zhi@siegeinvestment.com</a></p>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Siege Investment. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default LoginPage;